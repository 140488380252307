import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { Formik, Form, ErrorMessage, Field } from 'formik';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import CommonButton from "../components/CommonButton";
import CustomField from '../components/CustomField';
import { useTranslation } from "react-i18next";

const GenericForm = ({ initialValues, validationSchema, onSubmit, fields, recaptchaAction }) => {
  const { t } = useTranslation();
  const [submissionStatus, setSubmissionStatus] = useState(null); 
  const [initialHeight, setInitialHeight] = useState(null);
  const formRef = useRef(null);

  const [selectedFileNames, setSelectedFileNames] = useState({});
  const handleFileSelected = (fieldName, fileName) => {
    setSelectedFileNames(prevState => ({
      ...prevState,
      [fieldName]: fileName,
    }));
  };

  useEffect(() => {
    if (formRef.current && initialHeight === null) {
      setInitialHeight(formRef.current.offsetHeight);
    }
  }, [initialHeight]);

  const CustomErrorMessage = ({ name }) => (
    <ErrorMessage name={name} render={msg => 
      msg === 'Required' ? <span className="text-sm font-medium text-red-500">* {t('general.form_required_field')}</span> : <div className="text-sm font-medium text-red-500">* {msg}</div>
    }/>
  );

  const { executeRecaptcha } = useGoogleReCaptcha();

  const handleSubmit = async (values, actions) => {
    setSubmissionStatus('loading');

    try {
      let recaptchaToken = '';
      if (executeRecaptcha) {
        recaptchaToken = await executeRecaptcha(recaptchaAction);
      } else {
        console.warn('executeRecaptcha is not available');
      }

      // Tehdään kentistä FormData objekti, joka voi sisältää multipart mediaa
      const formData = new FormData();

      // Lisätään captcha token
      formData.append('recaptchaToken', recaptchaToken);

      // Lisätään tiedostokentät
      fields.filter(field => field.type === 'file').forEach(field => {
        if (values[field.name]) {
          formData.append(field.name, values[field.name]);
        }
      });

      // Lisätään muut kuin tiedostokentät
      Object.keys(values).forEach(key => {
        if (!fields.find(field => field.type === 'file' && field.name === key)) {
          formData.append(key, values[key]);
        }
      });

      // Kutsutaan parametrinä passattu funktio
      const response = await onSubmit(formData, actions);

      // d) Handle server response
      if (response?.status === 200) {
        setSubmissionStatus('success');
      } else {
        setSubmissionStatus('error');
      }
    } catch (error) {
      console.error('Error in handleSubmit:', error);
      setSubmissionStatus('error');
    } finally {
      actions.setSubmitting(false);
    }
  };

  const handleRemoveFile = (fieldName) => {
    setSelectedFileNames(prevState => ({
      ...prevState,
      [fieldName]: ''
    }));
  };

  // Etsitään tiedostonlähetyskentät
  const fileFields = fields.filter(field => field.type === 'file');
  const nonFileFields = fields.filter(field => field.type !== 'file' && field.type !== 'checkbox');

  // Yksinkertaiset näkymät tiloille
  if (submissionStatus === 'loading') {
    return (
      <div className="flex justify-center font-bold" style={{ minHeight: initialHeight }} ref={formRef}>
        <div className="animate-spin rounded-full h-32 w-32 border-t-4 border-b-4 border-highlight"></div>
      </div>
    );
  }

  if (submissionStatus === 'success') {
    return (
      <div className="flex justify-center font-medium" style={{ minHeight: initialHeight }} ref={formRef}>
        <div>{t("general.form_success")}</div>
      </div>
    );
  }

  if (submissionStatus === 'error') {
    return (
      <div className="flex flex-col gap-2 justify-start font-medium" style={{ minHeight: initialHeight }} ref={formRef}>
        <div>{t("general.form_error1")}</div>
        <div>{t("general.form_error2")}</div>
      </div>
    );
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      encType="multipart/form-data"
    >
      {() => (
        <Form className="space-y-2" encType="multipart/form-data" ref={formRef} style={{ minHeight: initialHeight }}>

          {/* Renderöidään ensin kaikki muut kentät paitsi tiedostonlähetyskentät */}
          {nonFileFields.map((field) => (
            <div key={field.name} className="flex flex-col max-w-[100%]">
              <div className="flex flex-row justify-between">
                <label htmlFor={field.name} className="text-sm font-medium">{field.label}</label>
                <CustomErrorMessage name={field.name} />
              </div>
              <CustomField field={field} initialValues={initialValues}/>
            </div>
          ))}
          {/* Renderöidään tiedostonlähetyskentät */}
          {fileFields.map((field) => (
            <div key={field.name} className="flex flex-row items-center w-full gap-4">
              {/* Label ja Painike */}
              <div className="flex w-1/4 md:w-1/3 lg:w-2/5 items-center">
                <div className="w-2/5"> 
                  <label htmlFor={field.name} className="text-sm font-medium truncate pr-2">{field.label}</label>
                </div>
                <div className="w-3/5">
                  <CustomField field={field} onFileSelected={handleFileSelected} />
                </div>
              </div>
              {/* Tiedostonimi ja poistopainike */}
              <div className="flex w-3/4 md:w-2/3 lg:w-3/5 items-center justify-between">
                {selectedFileNames[field.name] ? (
                  <>
                  <p className="truncate overflow-hidden whitespace-nowrap text-sm pl-6">
                    {selectedFileNames[field.name].length > 15   ? selectedFileNames[field.name].substring(0, 15) + '...' : selectedFileNames[field.name]}
                  </p>
                  <button onClick={() => handleRemoveFile(field.name)} className="px-2 py-1">
                    <svg className="w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                      <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path>
                    </svg>
                  </button>
                  </>
                ) : (
                  <span className="truncate overflow-hidden whitespace-nowrap text-sm pl-6">{t("general.not_selected")}</span>
                )}
              </div>
            </div>
          ))}
          {/* Renderöidään checkbox */}
          <div className="flex flex-row justify-between items-center">
            <div className="flex items-center">
              <Field name="privacyPolicyAccepted" type="checkbox" className="mr-2" />
              <label className="text-sm font-medium">{t("general.form_privacy")}
                <Link to="/privacy_policy" className="underline">
                  {t("general.form_privacy_policy")}
                </Link>
              </label>                
            </div>
            <div>
              <CustomErrorMessage name="privacyPolicyAccepted" />
            </div>
          </div>

          <div className='flex justify-end content-end'>
            <CommonButton
              type="submit"
              special
              className="px-6 py-2 border-white focus:outline-none hover:shadow-subtle-glow transition-transform transform hover:scale-105"
            >
              {t("general.submit")}
            </CommonButton>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default GenericForm;
